// src/components/NewsPage.js

import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import NewsCard from '../components/NewsCard';
import Footer from '../components/Footer';

// Приклад даних новин з фотографіями
const newsArticles = [
  {
    id: 1,
    title: 'Гена на',
    snippet: 'Гена на мотоциклі. А раніше уявляли що  нейронні мережі будуть працбвати на тяжких монотонних роботах а люди на різних креативних роботах але це реальність де все навпаки',
    link: '#',
    image: '/images/news/Gena.jpg', // Заміни URL на реальні зображення
  },
  {
    id: 2,
    title: 'Чаклун',
    snippet: 'Ось цей чаклун виліз і вкрав дзвоний айайа',
    link: '#',
    image: '/images/news/wizard.jpg', // Заміни URL на реальні зображення
  },
  {
    id: 3,
    title: 'Співучі тераси',
    snippet: "Дуже важдива пам'ятка",
    link: '#',
    image: '/images/news/spivuchi.jpg', // Заміни URL на реальні зображення
  },
  {
    id: 4,
    title: 'це назва четвертої Новини',
    snippet: 'Це короткий опис новини 4. Далі текст може продовжуватися...',
    link: '#',
    image: '/images/news/niggas.jpg', // Заміни URL на реальні зображення
  },
  // Додай інші новини за потреби
];

const NewsPage = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Можна завантажити новини з API або іншого джерела
    setEvents(newsArticles);
  }, []);

  return (
    <div>
      <Navbar />
      <div className="p-6 bg-gray-100 min-h-screen">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-900">Новини</h1>
        <div className="flex flex-wrap justify-center gap-6">
          {events.map(article => (
            <NewsCard newsArticle={article} key={article.id} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsPage;
