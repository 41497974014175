import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white text-center px-5 py-10">
      <p className=" text-green-900 font-nunito font-medium">© 2024 Костянтинівський ліцей Краснокутської громади Богодухівського району Харківської області.</p>
    </footer>
  );
};

export default Footer; 
