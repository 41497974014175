import React, { useState, useEffect } from 'react';
import './Header.css'; // Підключення стилів

function Header() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsVisible(true), 500); // Delay before launch animation
    }, []);

  

    return (
    <header className="header" style={{backgroundImage: 'url(/images/index/header-background.png)'}}>
        <div className={`header-content ${isVisible ? 'visible' : ''}`}>
            <h1 className="header-title">Навчайся онлайн з комфортом</h1>
            <p className="header-text">
                Костянтинівський ліцей — це чудове місце для учнів та вчителів для того, щоб підійматись та ставати кращими кожен день.
            </p>
            <div className="cta-buttons">
                <button className="btn btn-primary">Почати навчання</button>
                <button className="btn btn-secondary">Дізнатися більше</button>
            </div>
        </div>
    </header>
    );
};

export default Header;